<template>
  <div v-if="defaultValues" class="p-0 m-0">
    <Form
      ref="form"
      :defaultValues="defaultValues"
      v-bind="$attrs"
      class="rde-form"
      :before="handleSubmitBefore"
      v-on="$listeners"
    >
      <template #default="{ values, setValue, validate }">
        <EditRDE
          :workspace="workspace"
          :mode="mode"
          :adminAuth="adminAuth"
          :isSystemAdmin="isSystemAdmin"
          :values="values"
          :setValue="setValue"
          :defaultValues="defaultValues"
          :storage_type_items="storage_type_items"
          :validate="validate"
        />
      </template>
    </Form>
  </div>
</template>
<script>
import Form from "@/components/atoms/Form/Form.vue";

import EditRDE from "@/components/newWorkspace/EditRDE/EditRDE.vue";
import { cloneDeep, get, isEmpty, set } from "lodash";
import { mapState } from "vuex";
import CommonUIControl from "@/helper/CommonUIControl";
import {
  addWorkspaceRde,
  getWorkspaceById,
  getWorkspaceRdeById,
  editWorkspaceRde,
  addWorkspaceRdeMulti,
  getStorageType,
  getUserWorkspaceRole,
} from "@/service/apis/workspaceApis";
import { RequestMixin } from "@/mixins/RequestMixin";
import { WORKSPACE_ROLE } from "@/service/constants";
import { roundNumber } from "@/helper/utils";

const defaultValues = {
  description: "",
  userName: "",
  namespace: "",
  useExtention: false,
  useAIExtention: false,
  aiPackageType: "",
  packageType: [],
  replicas: 1,
  //serviceTypes: ["vscode", "webssh", "notebook"],
  serviceTypes: ["vscode"],
  portList: [],
  resourceSize: {
    infraId: null,
    cpu: null,
    memory: null,
  },
  diskSize: {
    disk: 20,
    type: "block-storage",
  },
  vscode: {
    useImage: false,
    ideTypes: ["web"],
    image: "",
    useGit: false,
    git: {
      id: "",
      repository: "",
      token: "",
      branch: "",
    },
    setResource: true,
    resourceSize: {
      cpu: "",
      cpuUnit: "core",
      memoryUnit: "GiB",
      memory: "",
    },
    useFiles: false,
    files: [],
  },
  webssh: {
    useImage: false,
    image: "",
    setResource: true,
    resourceSize: {
      cpu: "",
      memory: "",
      cpuUnit: "core",
      memoryUnit: "GiB",
    },
  },
  notebook: {
    useImage: false,
    image: "",
    setResource: true,
    resourceSize: {
      cpu: "",
      memory: "",
      cpuUnit: "core",
      memoryUnit: "GiB",
    },
  },
  rdeServiceType: {
    rdeType: "user",
    managedServices: [
      {
        name: "",
        wsName: "",
        appName: "",
      },
    ],
  },
  tolerations: [],
  selectedUsers: [],
  wsName: "",
  appName: "",
  displayName: "",
  useInstallPackages: false,
  installPackages: {
    aptPackages: "",
    pipPackages: "",
    javaVersion: "",
    nodeVersion: "",
    preflightConfig: {
      configType: "",
      name: "",
    },
    mountedConfigList: [],
    mountedFSList: [],
  },
  instanceType: "node",
  fargate: {
    key: "",
    value: "",
  },
};

export default {
  components: { EditRDE, Form },
  rules: [RequestMixin],
  data: () => ({
    mode: "CREATE",
    workspaceId: "",
    rdeId: "",
    workspace: {},
    rde: {},
    storage_type_items: [],
    adminAuth: false,
  }),
  computed: {
    ...mapState({
      accountInfo: (state) => state.accountInfo,
      isSystemAdmin: (state) => {
        return (
          ["administrator", "admin"].includes(state.accountInfo.username) ||
          state.accountInfo.auth?.toLowerCase?.() === "administrator"
        );
      },
    }),
    defaultValues() {
      if (this.mode === "CREATE") {
        // this is create page
        let newDefaultValues = cloneDeep(defaultValues);

        // set default for resource size
        newDefaultValues.vscode.resourceSize.cpu = 0;
        newDefaultValues.vscode.resourceSize.memory = 0;

        newDefaultValues.webssh.resourceSize.cpu = 0;
        newDefaultValues.webssh.resourceSize.memory = 0;

        newDefaultValues.notebook.resourceSize.cpu = 0;
        newDefaultValues.notebook.resourceSize.memory = 0;

        if (this.accountInfo?.username) {
          newDefaultValues.userName = this.accountInfo?.username;
          //newDefaultValues.permission.serviceAccountName = `${this.accountInfo.username}-ide-account`;
        }

        // newDefaultValues.permission.role = "developer";
        // newDefaultValues.permission.scope = "namespace";

        return newDefaultValues;
      } else if (!isEmpty(this.rde)) {
        // this is edit page
        // do something if transform data is neccessary
        return this.transformDetailsRDE(cloneDeep(this.rde));
      }

      return null;
    },
  },
  methods: {
    async handleSubmitBefore(values) {
      const newValues = cloneDeep(values);
      const users = newValues.selectedUsers;
      delete newValues.selectedUsers;

      if (!newValues.serviceTypes.length) {
        CommonUIControl.ShowErrorToast(
          "You should have at least one type of service!",
        );
        return false;
      }

      if (!newValues.portList.length) {
        CommonUIControl.ShowErrorToast("You should have at least one port!");
        return false;
      }

      if (!newValues.vscode.ideTypes.length) {
        return false;
      }

      if (!newValues.vscode.ideTypes.length) {
        return false;
      }

      const usedVscode = newValues.serviceTypes.includes("vscode");

      if (!usedVscode) {
        newValues.vscode = defaultValues.vscode;
        newValues.useExtention = false;
        newValues.packageType = [];
      } else {
        if (!newValues.vscode) {
          newValues.vscode = {};
        }

        if (!newValues.vscode.useGit) {
          if (!newValues.vscode.git) {
            newValues.vscode.git = {};
          }

          newValues.vscode.git.id = "";
          newValues.vscode.git.repository = "";
          newValues.vscode.git.branch = "";
          newValues.vscode.git.token = "";
        }
        if (!newValues.vscode.setResource) {
          newValues.vscode.resourceSize = {
            cpu: "",
            memory: "",
          };
        } else {
          this.parseResourceSizeToSave(newValues.vscode.resourceSize);
        }

        if (!newValues.vscode.useFiles) {
          newValues.vscode.files = [];
        }

        if (!newValues.vscode.useImage || !newValues.vscode.image) {
          delete newValues.vscode.image;
        }
        delete newValues.vscode.useImage;

        if (newValues.useAIExtention && newValues.aiPackageType) {
          newValues.packageType = (newValues.packageType || []).concat([
            newValues.aiPackageType,
          ]);
        }
        delete newValues.useAIExtention;
        delete newValues.aiPackageType;
      }

      const usedWebSsh = newValues.serviceTypes.includes("webssh");

      if (!usedWebSsh) {
        newValues.webssh = null;
      } else {
        if (!newValues.webssh) {
          newValues.webssh = {};
        }

        if (!newValues.webssh.setResource) {
          newValues.webssh.resourceSize = {
            cpu: "",
            memory: "",
          };
        } else {
          this.parseResourceSizeToSave(newValues.webssh.resourceSize);
        }

        if (!newValues.webssh.useImage || !newValues.webssh.image) {
          delete newValues.webssh.image;
        }
        delete newValues.webssh.useImage;
      }
      const usedNotebook = newValues.serviceTypes.includes("notebook");

      if (!usedNotebook) {
        newValues.notebook = defaultValues.notebook;
      } else {
        if (!newValues.notebook) {
          newValues.notebook = {};
        }

        if (!newValues.notebook.setResource) {
          newValues.notebook.resourceSize = {
            cpu: "",
            memory: "",
          };
        } else {
          this.parseResourceSizeToSave(newValues.notebook.resourceSize);
        }

        if (!newValues.notebook.useImage || !newValues.notebook.image) {
          delete newValues.notebook.image;
        }
        delete newValues.notebook.useImage;
      }

      // map resource size for overall RDE
      newValues.resourceSize.cpu = `${newValues.resourceSize.cpu}m`;
      newValues.resourceSize.memory = `${newValues.resourceSize.memory}Mi`;
      newValues.diskSize.disk = `${newValues.diskSize.disk}Gi`;

      newValues.userName = newValues?.userName || this.accountInfo.username;
      newValues.wsName = this.workspace.name;

      newValues.rdeServiceType = {
        rdeType: "user",
        managedServices: [
          {
            name: "",
            wsName: "",
            appName: "",
          },
        ],
      };

      // currently set replicas fixed value 1
      newValues.replicas = 1;

      // map port list
      newValues.portList = newValues.portList
        ?.filter((p) => p?.name && p?.port)
        .map((port) => {
          return {
            name: port.name,
            protocol: port.protocol,
            port: port.port,
            targetPort: port.port,
          };
        });

      // map tolerations
      if (newValues?.instanceType === "fargate") {
        newValues.tolerations = [
          {
            key: newValues.fargate.key,
            value: newValues.fargate.value,
          },
        ];
        newValues.diskSize.type = "file-storage";
      } else {
        newValues.tolerations =
          newValues.tolerations?.map((item) =>
            typeof item === "string" ? JSON.parse(item || {}) : item,
          ) || [];
      }

      delete newValues.fargate;

      // handle install packages
      if (!newValues.useInstallPackages) {
        delete newValues.installPackages;
      }

      delete newValues.useInstallPackages;

      let saveFunction;
      CommonUIControl.ShowUIProgress();

      if (
        this.mode === "CREATE" &&
        this.adminAuth &&
        newValues?.showCreateUser &&
        users?.length > 0
      ) {
        const dataList = users.map((user) => {
          let newUserValues = cloneDeep(newValues);
          newUserValues.userName = user;
          delete newUserValues.showCreateUser;

          return newUserValues;
        });

        saveFunction = addWorkspaceRdeMulti({
          dataList,
          namespace: newValues.namespace,
          workspaceId: this.workspaceId,
          domainName: "localDomainRS",
        });
      } else if (this.mode === "CREATE") {
        delete newValues.showCreateUser;

        saveFunction = addWorkspaceRde({
          workspaceId: this.workspaceId,
          domainName: "localDomainRS",
          namespace: newValues.namespace,
          name: newValues.userName,
          ideConfigSpec: newValues,
        });
      } else {
        delete newValues.showCreateUser;

        saveFunction = editWorkspaceRde({
          rdeId: this.rdeId,
          ideConfigSpec: newValues,
        });
      }

      await saveFunction
        .then(() => {
          {
            this.$router.replace(
              `/newwp/list-rde/${this.workspaceId}${
                this.rdeId ? `/${this.rdeId}` : ""
              }`,
            );
            CommonUIControl.ShowSuccessToast(
              `${this.mode} RDE saved successfully!`,
              5000,
            );
          }
        })
        .catch((e) => {
          if (e.response?.data?.message) {
            CommonUIControl.ShowErrorToast(e.response?.data?.message);
          } else {
            CommonUIControl.ShowErrorToast(
              "An error occurred while saving RDE",
            );
          }
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });

      return false;
    },

    excludeUnitText(value) {
      const CHARACTERS_TO_REMOVE = ["m", "mi", "Gi", "Mi", "gi"];

      if (typeof value === "string") {
        CHARACTERS_TO_REMOVE.forEach((str) => {
          value = value.replace(new RegExp(str, "g"), "");
        });
        return Number(value);
      }
      return Number(value);
    },
    parseResourceSizeToSave(resourceSize) {
      resourceSize.cpu = `${
        resourceSize.cpuUnit === "core"
          ? Number(resourceSize.cpu) * 1000
          : resourceSize.cpu
      }m`;
      resourceSize.memory = `${
        resourceSize.memoryUnit === "GiB"
          ? Number(resourceSize.memory) * 1024
          : resourceSize.memory
      }Mi`;
      delete resourceSize.cpuUnit;
      delete resourceSize.memoryUnit;
    },
    transformDetailsRDE(rde) {
      const finalDetail = {
        ...defaultValues,
        ...rde,
        notebook: rde.notebook || defaultValues.notebook,
      };

      if (!finalDetail.vscode) {
        finalDetail.vscode = defaultValues.vscode;
      }

      if (!finalDetail.webssh) {
        finalDetail.webssh = defaultValues.webssh;
      }

      if (!finalDetail.notebook) {
        finalDetail.notebook = defaultValues.notebook;
      }

      // some infra variable is saved as 1000m, 2000Gi...
      // the below handles parsing them to number
      const infraVars = [
        "diskSize.disk",
        "resourceSize.cpu",
        "resourceSize.memory",
        "vscode.resourceSize.cpu",
        "vscode.resourceSize.memory",
        "webssh.resourceSize.cpu",
        "webssh.resourceSize.memory",
        "notebook.resourceSize.cpu",
        "notebook.resourceSize.memory",
      ];

      infraVars.forEach((key) => {
        if (get(finalDetail, key)) {
          set(finalDetail, key, this.excludeUnitText(get(finalDetail, key)));
        }
      });

      ["vscode", "webssh", "notebook"].forEach((service) => {
        if (finalDetail[service]?.resourceSize?.cpu >= 1000) {
          // parse to core
          finalDetail[service].resourceSize.cpuUnit = "core";

          finalDetail[service].resourceSize.cpu = roundNumber(
            finalDetail[service].resourceSize.cpu / 1000,
            2,
          );
        } else if (
          typeof finalDetail[service]?.resourceSize?.cpu === "number" &&
          finalDetail[service]?.resourceSize?.cpu < 1000 &&
          finalDetail[service]?.resourceSize?.cpu > 500
        ) {
          finalDetail[service].resourceSize.cpuUnit = "millicore";
        }

        if (finalDetail[service]?.resourceSize?.memory >= 1024) {
          finalDetail[service].resourceSize.memoryUnit = "GiB";
          finalDetail[service].resourceSize.memory = roundNumber(
            finalDetail[service].resourceSize.memory / 1024,
            2,
          );
        } else if (
          typeof finalDetail[service]?.resourceSize?.memory === "number" &&
          finalDetail[service]?.resourceSize?.memory < 1024 &&
          finalDetail[service]?.resourceSize?.memory > 512
        ) {
          finalDetail[service].resourceSize.memoryUnit = "MiB";
        }
      });

      // this is a fix workaround when BE does not return the correct value (useGit, setResource)
      if (
        !finalDetail?.vscode?.useGit &&
        finalDetail?.vscode?.git?.repository
      ) {
        finalDetail.vscode.useGit = true;
      }

      if (
        !finalDetail?.webssh?.setResource &&
        finalDetail?.webssh?.resourceSize?.cpu
      ) {
        finalDetail.webssh.setResource = true;
      }

      if (finalDetail?.packageType?.length) {
        finalDetail.useExtention = true;
      }

      // map use install packages
      finalDetail.useInstallPackages = !isEmpty(rde?.installPackages);

      if (finalDetail.useInstallPackages) {
        finalDetail.installPackages.mountedFSList =
          finalDetail.installPackages.mountedFSList || [];

        finalDetail.installPackages.mountedConfigList =
          finalDetail.installPackages.mountedConfigList || [];

        finalDetail.installPackages.preflightConfig = finalDetail
          .installPackages.preflightConfig || {
          configType: "",
          name: "",
        };
      } else {
        finalDetail.installPackages = {
          aptPackages: "",
          pipPackages: "",
          javaVersion: "",
          nodeVersion: "",
          preflightConfig: {
            configType: "",
            name: "",
          },
          mountedConfigList: [],
          mountedFSList: [],
        };
      }

      // map images
      if (finalDetail.vscode?.image) {
        finalDetail.vscode.useImage = true;
      }

      if (finalDetail.webssh?.image) {
        finalDetail.webssh.useImage = true;
      }

      if (finalDetail.notebook?.image) {
        finalDetail.notebook.useImage = true;
      }

      return finalDetail;
    },
  },
  mounted() {
    const workspaceId = this.workspaceId;
    const rdeId = this.$route.params?.rdeId;
    this.rdeId = rdeId;

    this.mode = this.$route.fullPath.includes("create") ? "CREATE" : "EDIT";

    const requests = [];

    if (workspaceId) {
      requests.push(
        getWorkspaceById({ workspaceId })
          .then((res) => {
            this.workspace = res?.data?.data;
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch workspace failed");
          }),
      );

      if (!this.adminAuth) {
        requests.push(
          getUserWorkspaceRole(this.accountInfo.username)
            .then((res) => {
              if (res?.data) {
                const userRole = res.data.data;

                this.adminAuth = userRole.find(
                  (item) =>
                    item.id === this.workspaceId &&
                    item.role === WORKSPACE_ROLE.ADMINISTRATOR,
                )
                  ? true
                  : false;
              }
            })
            .catch(() => {
              CommonUIControl.ShowErrorToast("Fetch workspace role failed");
            }),
        );
      }
    }
    if (this.mode === "EDIT" && rdeId) {
      requests.push(
        getWorkspaceRdeById({ rdeId })
          .then((res) => {
            this.rde = res?.data;
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch rde failed");
          }),
      );
    }

    if (workspaceId) {
      requests.push(
        getStorageType(workspaceId)
          .then((res) => {
            this.storage_type_items = res?.data?.data || [];
          })
          .catch(() => {
            CommonUIControl.ShowErrorToast("Fetch storage type failed");
          }),
      );
    }

    if (requests.length) {
      CommonUIControl.ShowUIProgress();

      Promise.allSettled(requests).finally(() => {
        CommonUIControl.HideUIProgress();
      });
    }
  },
  beforeMount() {
    this.workspaceId = this.$route.params?.workspaceId;
    this.adminAuth = this.isSystemAdmin;
  },
};
</script>

<style lang="scss"></style>

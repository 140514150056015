
<div class="p-0 m-0">
  <div class="header">
    <div class="left-header">
      <img
        src="~@/assets/img/arrow-back.svg"
        alt="SVG Icon"
        :style="{ cursor: 'pointer' }"
        @click="goBack"
      />
      <span>{{ mode === "EDIT" ? "Edit RDE" : "Create new RDE" }}</span>
    </div>

    <div class="right-header">
      <div v-if="adminAuth && mode === 'CREATE'" class="switch-v2-group">
        <Sw
          v-model="values.showCreateUser"
          inset
          appendClass="switch-v2"
        ></Sw>
        <span class="text-switch">Create User/Group RDE</span>
      </div>
      <Button :size="'md'" @click="goBack">Cancel</Button>
      <Button :type="'submit'" color="primary" :size="'md'" text fill>
        Apply
      </Button>
    </div>
  </div>
  <div class="rde-section">
    <div class="grid grid-cols-2">
      <Input
        v-model="values.appName"
        label="* RDE Name (12 Characters)"
        appendClass="input-text-field-v2"
        :rules="[
          rules.required,
          rules.name,
          mode !== 'EDIT' ? rules.maxLength(12) : () => true,
        ]"
        :disabled="mode === 'EDIT'"
      />
      <Input
        v-model="values.displayName"
        label="* Display Name"
        appendClass="input-text-field-v2"
        :rules="[rules.required]"
      />
    </div>

    <Input
      v-model="values.description"
      label="Description"
      appendClass="input-text-field-v2"
    />
    <Select
      v-model="values.namespace"
      :items="namespace_items"
      :rules="[rules.required]"
      :disabled="mode === 'EDIT'"
      label="* Namespace"
      appendClass="select-v2"
      :solo="false"
      @input="handleChangeNamespace"
    ></Select>
    <div class="grid grid-cols-2">
      <InfraSelect
        :infraBaselineList="infraBaselineList"
        :values="values"
        :onChangeInfra="(obj) => onChangeInfraRDE(obj, true)"
        :defaultValue="defaultValueInfra"
      />
      <NumberInput
        v-model="values.diskSize.disk"
        label="Disk - GiB"
        :format="'none'"
        appendClass="input-text-field-v2"
        :rules="[rules.required, maxDiskValidate]"
        :disabled="mode === 'EDIT'"
        :max="50"
      />
    </div>
    <div v-if="selectedFargateWs" class="switch-v2-group">
      <!-- *************** -->
      <Tooltip
        v-if="!allowSwitchFaragte"
        text="Instance Type cannot be changed due to <br /> Storage is changed"
        position="right"
        targetSelf
      >
        <Sw
          :value="values.instanceType === 'fargate'"
          inset
          appendClass="switch-v2"
          :disabled="!allowSwitchFaragte"
          @input="toggleFargate"
        ></Sw>
        <span class="text-switch">Fargate</span>
      </Tooltip>
      <!-- ************** -->
      <Fragment v-else>
        <Sw
          :value="values.instanceType === 'fargate'"
          inset
          appendClass="switch-v2"
          @input="toggleFargate"
        ></Sw>
        <span class="text-switch">Fargate</span>
      </Fragment>
    </div>

    <div class="grid grid-cols-2 mt-1">
      <v-autocomplete
        v-if="!fargateMode"
        :value="values.tolerations"
        :items="node_group_items_by_fargate"
        item-text="value"
        :item-value="mapValueNodeGroup"
        label="* Target Node"
        placeholder="Select Target Node"
        class="app-g-autocomplete-v2"
        :menu-props="{ contentClass: 'hide-check-box-auto-complete' }"
        clearable
        outlined
        dense
        :rules="[rules.required]"
        multiple
        @change="onChangeTargetNode"
      />
      <Select
        v-if="!fargateMode"
        v-model="values.diskSize.type"
        :items="storage_type_items"
        :rules="[rules.required]"
        label="*Storage Type"
        appendClass="select-v2"
        :disabled="mode === 'EDIT'"
        :solo="false"
      ></Select>
      <Input
        v-if="fargateMode"
        v-model="values.fargate.key"
        label="* Key"
        appendClass="input-text-field-v2"
        :rules="[rules.required]"
      />
      <Input
        v-if="fargateMode"
        v-model="values.fargate.value"
        label="* Value"
        appendClass="input-text-field-v2"
        :rules="[rules.required]"
      />
    </div>
  </div>
  <div v-if="values.serviceTypes" class="rde-section">
    <div class="rde-title">Service Type</div>
    <div class="service-type">
      <div class="switch-v2-group">
        <Sw
          :value="values.serviceTypes.includes('vscode')"
          inset
          appendClass="switch-v2"
          :disabled="mode === 'EDIT'"
          @input="
            (value) =>
              setServiceTypes({ setValue, values, name: 'vscode', value })
          "
        ></Sw>
        <span class="text-switch">Visual Studio Code</span>
      </div>
      <div class="switch-v2-group">
        <Sw
          :value="values.serviceTypes.includes('webssh')"
          inset
          appendClass="switch-v2"
          :disabled="mode === 'EDIT'"
          @input="
            (value) =>
              setServiceTypes({ setValue, values, name: 'webssh', value })
          "
        ></Sw>
        <span class="text-switch">SSH</span>
      </div>
      <div class="switch-v2-group">
        <Sw
          :value="values.serviceTypes.includes('notebook')"
          inset
          appendClass="switch-v2"
          :disabled="mode === 'EDIT'"
          @input="
            (value) =>
              setServiceTypes({
                setValue,
                values,
                name: 'notebook',
                value,
              })
          "
        ></Sw>
        <span class="text-switch">Jupyter</span>
      </div>
    </div>
    <div v-if="!values.serviceTypes.length" class="text-xs text-error h-5">
      Require at least one service.
    </div>
  </div>
  <div v-if="values.serviceTypes.includes('vscode')" class="rde-section">
    <div class="rde-title">IDE Type</div>
    <v-btn-toggle
      v-model="values.vscode.ideTypes"
      class="mt-2"
      dense
      variant="outlined"
      color="primary accent-3"
      multiple
      divided
    >
      <v-btn value="web"> Web </v-btn>
      <v-btn value="ssh"> SSH </v-btn>
    </v-btn-toggle>

    <div
      v-if="!values.vscode.ideTypes.length"
      class="text-xs text-error h-5 mt-1"
    >
      Required
    </div>

    <!-- <div v-if="showSshOnlyError" class="text-xs text-error h-5">
      VS Code - SSH only is not supported
    </div> -->
  </div>
  <div class="rde-section">
    <div class="rde-title">Port List</div>
    <AddPortInputListV2
      :portList="values.portList"
      @change="
        (newPorts) => {
          setValue('portList', newPorts);
        }
      "
    />
  </div>
  <div class="rde-section">
    <div class="switch-v2-group gap-2">
      <div class="rde-title">Install Packages</div>
      <Sw
        v-model="values.useInstallPackages"
        inset
        appendClass="switch-v2"
      ></Sw>
    </div>
    <transition name="fade" mode="out-in">
      <InstallPackage
        v-if="values.useInstallPackages === true"
        :values="values"
        :setValue="setValue"
        :installPackages="installPackages"
        :validate="validate"
      />
    </transition>
  </div>
  <div
    v-if="values.serviceTypes && values.serviceTypes.includes('vscode')"
    key="vscode"
    class="rde-section"
  >
    <div class="rde-title">Visual Studio Code Setting</div>
    <VSCodeInput
      :values="values"
      :set-value="setValue"
      :showImageCheckbox="imageVsCodeEnabled"
      :defaultValues="defaultValues"
    />

    <CustomMultiSelectRde
      v-if="values.vscode.useImage && imageVsCodeEnabled"
      key="vscode-image-web"
      v-model="values.vscode.image"
      label="Please select Image"
      :items="images.vscode?.['docker-list']"
      placeholder="Please select Image"
      class="app-g-autocomplete-v2"
      multiple
      outlined
      dense
    />

    <InfraSizeInput
      serviceType="vscode"
      :values="values"
      :requirementInfraResource="guideInfraResource?.requirements"
      :validate="validate"
      @value-change="infraSizeChange"
    />
  </div>

  <div
    v-if="values.serviceTypes && values.serviceTypes.includes('webssh')"
    key="webssh"
    class="rde-section"
  >
    <div class="rde-title">Web SSH Setting</div>
    <Checkbox
      v-if="images?.webssh?.['docker-list'].length > 0"
      v-model="values.webssh.useImage"
      label="Image"
    />
    <CustomMultiSelectRde
      v-if="values.webssh.useImage && imageWebsshEnabled"
      v-model="values.webssh.image"
      label="Please select Image"
      :items="images.webssh?.['docker-list']"
      placeholder="Please select Image"
      class="app-g-autocomplete-v2"
      multiple
      outlined
      dense
    />
    <InfraSizeInput
      serviceType="webssh"
      :values="values"
      :requirementInfraResource="guideInfraResource?.requirements"
      :validate="validate"
      @value-change="infraSizeChange"
    />
  </div>

  <div
    v-if="values.serviceTypes && values.serviceTypes.includes('notebook')"
    key="notebook"
    class="rde-section"
  >
    <div class="rde-title">Jupyter Setting</div>
    <Checkbox
      v-if="images.jupyter?.['docker-list'].length > 0"
      v-model="values.notebook.useImage"
      label="Image"
    />
    <CustomMultiSelectRde
      v-if="
        values.notebook.useImage && images.jupyter?.['docker-list'].length > 0
      "
      v-model="values.notebook.image"
      label="Image"
      :items="images.jupyter?.['docker-list']"
      placeholder="Select Image"
      class="app-g-autocomplete-v2"
      multiple
      outlined
      dense
    />
    <!-- <Checkbox
      v-if="!!images.notebook.length"
      v-model="values.notebook.useImage"
      label="Image"
    />
    <v-autocomplete
      v-if="values.notebook.useImage && !!images.notebook.length"
      v-model="values.notebook.image"
      :items="images.notebook"
      label="Image"
      small-chips
      placeholder="Select Image"
      class="app-g-autocomplete-v2"
      clearable
      outlined
      dense
    /> -->
    <InfraSizeInput
      serviceType="notebook"
      :values="values"
      :requirementInfraResource="guideInfraResource?.requirements"
      :validate="validate"
      @value-change="infraSizeChange"
    />
  </div>

  <div v-if="values.showCreateUser" class="rde-section">
    <div class="rde-title">Create Multiple RDE</div>
    <UserInput
      :values="values"
      :setValue="setValue"
      :projectId="workspace?.projectId"
      mapFields="selectedUsers"
      :accountInfo="accountInfo"
    />
  </div>
</div>
